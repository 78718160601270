<template>
    <v-dialog v-model="bookingNotification.display" no-click-animation persistent>
        <v-snackbar id="bookingnoti"
            v-if="bookingNotification.display"
            v-model="bookingNotification.display"
            :bottom="snackbar_props.position === 'bottom'"
            :top="snackbar_props.position === 'top'"
            :left="snackbar_props.position === 'left'"
            :right="snackbar_props.position === 'right'"
            :centered="snackbar_props.position === 'centered'"
            :color="snackbar_props.color"
            :timeout="snackbar_props.timeout"
            :multi-line="snackbar_props.mode === 'multi-line'"
            :vertical="snackbar_props.mode === 'vertical'"
            :absolute="snackbar_props.absolute"
            :dark="snackbar_props.theme==='dark'"
            :light="snackbar_props.theme==='light'"
          >

            <v-container pt-0 pb-0 px-0 fluid>
                <v-row justify="center" class="message">
                    <template v-if="bookingNotification.context=='success'">
                        <str v-if="!bookingNotification.subcontext" index="event > booked > success noti-enrollment"/>
                        <str v-else-if="bookingNotification.subcontext=='already_enrolled'" index="event > booked > already-enrolled"/>
                    </template>
                    <template v-if="bookingNotification.context=='failure'">
                        <str index="message > system_unexpected_error"/>
                        <str v-if="bookingNotification.context=='failure'" index="message > try_again"/>
                    </template>
                </v-row>

                <template v-if="bookingNotification.context=='success'">
                    <v-row v-if="snackbar_props.render_as_html">
                        <span v-html="bookingNotification.event_date"></span>
                    </v-row>
                    <div no-gutters v-else dark class="details">
                        <v-row align="center">
                            <v-col cols="3"><v-icon color="white">mdi-calendar-check-outline</v-icon></v-col>
                            <v-col cols="9">{{bookingNotification.event_date}}</v-col>
                        </v-row>
                        <v-row>
                            <div><str index="event > booked > event_title"/></div>
                            <div>{{bookingNotification.event_title}}</div>
                        </v-row>
                    </div>
                </template>
                <div class="bottomButton">
                    <v-btn @click="closeBookingNoti()" v-html="labelTerm('button > register another person')"></v-btn>
                </div>
            </v-container>
            <template v-slot:action="{ attrs }">
                <v-btn icon style="position: absolute; top:4px; right: 4px;" width="32" height="32" color="transparent" @click="closeBookingNoti()" v-bind="attrs">
                    <v-icon color="white" small>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>
export default {
    name: "BookingNotification",
    data: function(){
        return {
            snackbar_props: {
                theme: 'light',
                color: '#f2f2f2',
                position: 'centered',
                timeout: -1,
                mode: 'multi-line',
                absolute: false,
                render_as_html: false
            }
        }
    },
    methods: {
        closeBookingNoti: function(){
            let cloneBookingNotification = {...(this.bookingNotification)}
            cloneBookingNotification.display = false;
            this.$store.dispatch('bookingNotification', cloneBookingNotification);
            return window.location.href = process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+`:8080${this.$route.path}` : this.$store.getters.baseURL;
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language;
        },
        bookingNotification: function(){
            return this.$store.getters.bookingNotification;
        }
    },
    watch: {
        bookingNotification: function(after, before){// eslint-disable-line no-unused-vars
            if (after.display===true){
                /*
                setTimeout( function(){
                    this.closeBookingNoti();
                }.bind(this),this.snackbar_props.timeout)
                 *
                 */
            }
        }
    }
}
</script>

<style lang="css" scoped>
    /deep/.v-snack__wrapper {
        max-width: 400px;
        min-width: 320px;
    }

    .row {
        margin: 0;
    }
</style>

<style>
    .v-application--is-ltr .v-snack__action {
        margin-right: 0px !important;
    }

    .v-snack__content {
        width: 100% !important;
        padding: 0 !important;

        /* border: 2px solid red; */
    }
</style>

<style lang='scss' scoped>

    #bookingnoti {

        .message {
            padding: 34px 64px;
            color: white;
            background: linear-gradient(to bottom, rgba(1, 60, 122, 1), rgba(31, 107, 184, 1));
            font-size: 16px;
            font-weight: 600;
        }

        .details {
            display: flex;

            > div {
                width: 50%;
                padding: 24px;

                // Left Side
                &:nth-of-type(1) {
                    color: white;
                    background-color: rgba(1, 60, 122, 1);
                    font-size: 14px;

                    // Icon
                    > div:nth-of-type(1) {
                        padding: 0;
                    }

                    // Date and time
                    > div:nth-of-type(2) {
                        padding: 0;
                    }
                }

                // Right Side
                &:nth-of-type(2) {
                    background-color: white;
                    font-size: 12px;

                    // Event Title
                    > div:nth-of-type(1) {
                        color: rgba(102, 102, 102, 1);
                    }

                    // Name
                    > div:nth-of-type(2) {
                        line-height: 14px;
                        color: rgba(33, 33, 33, 1);
                    }
                }
            }
        } // END details

        .bottomButton {
            padding: 16px;

            .v-btn {
                display: block;
                margin: 0 auto;
                color: white;
                background-color: rgba(1, 60, 122, 1);
                border-radius: 3px;
                cursor: pointer;
            }
        }
    }
</style>
