import Vue from 'vue'
import Vuex from 'vuex'
import cookie from '@/plugins/cookie'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // api: process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+"/vue_prj/janssenguasco/src/api/requestHandler.php" : "/src/api/requestHandler.php",
        // download_handler: process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+"/vue_prj/janssenguasco/src/api/downloadHandler.php" : "/src/api/downloadHandler.php",
        // baseURL: process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+"/vue_prj/janssenguasco/" : "https://"+window.location.hostname + "/",
        api: process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+"/vue_prj/janssenguasco/src/api/requestHandler.php" : "/src/api/requestHandler.php",
        download_handler: process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+"/vue_prj/janssenguasco/src/api/downloadHandler.php" : "/src/api/downloadHandler.php",
        baseURL: process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+"/vue_prj/janssenguasco/" : "https://"+window.location.hostname + "/",
        csrf: null,
        jwt: null,
        user: null,
        language: 'en',
        library: null,
        entryURL: null,
        programs: null,
        bookingNotification: {
            display: false,
            event_title: "Event sample",
            event_date: "EEEE, MMMM d, yyyy h:mm:ss a z",
            context: null, // 'success/failure'
            subcontext: null
        },
        campaign_id: null
    },
    getters: {
        api: function(state){
          return state.api
        },
        download_handler: function(state){
          return state.download_handler;
        },
        baseURL: function(state){
          return state.baseURL;
        },
        csrf: function(state){
          return state.csrf
        },
        jwt: function(state){
          return state.jwt
        },
        user: function(state){
          return state.user
        },
        language: function(state){
          return state.language
        },
        library: function(state){
          return state.library
        },
        entryURL: function(state){
          return state.entryURL;
        },
        programs: function(state){
          return state.programs;
        },
        bookingNotification: function(state) {
            return state.bookingNotification;
        },
        campaign_id: function(state){
            return state.campaign_id;
        }
    },
    actions: {
        language: function(context, payload){
          context.commit('language', payload);
        },
        library: function(context, payload){
          context.commit('library',payload);
        },
        csrf: function(context, payload){
          cookie.set('csrf', payload);
          context.commit('csrf', payload);
        },
        clear_csrf: function(context){
            cookie.clear('csrf');
            context.commit('csrf', null);
        },
        jwt: function(context, payload){
            cookie.set('jwt', payload);
            context.commit('jwt', payload);
        },
        clear_jwt: function(context){
            cookie.clear('jwt');
            context.commit('jwt', null);
            context.commit('user',null);
        },
        user: function(context, payload){
          context.commit('user', payload);
        },
        entryURL: function(context, payload){
          context.commit('entryURL', payload);
        },
        programs: function(context, payload){
          context.commit('programs', payload);
        },
        bookingNotification: function(context, payload){
            context.commit('bookingNotification', payload);
        },
        campaign_id: function(context, payload){
            context.commit('campaign_id', payload)
        }
    },
    mutations: {
        csrf: function(state , payload){
          state.csrf = payload
        },
        jwt: function(state , payload){
            state.jwt = payload;
            var jwt = payload;
            if(jwt){
                var token = parseJWT(jwt);
                this.dispatch('user',parseJSON(token.user));
            }

            function parseJSON(object){
              for(let key in object){
                var item = object[key];
                if(typeof item=='string' && item.length>=2){
                  try{
                    item = JSON.parse(item);
                    object[key] = item;
                  }catch(e){
                    var garbage = e;
                    garbage = null;
                    if(garbage!=null){
                      console.log('garbage',garbage);
                    }
                  }
                }
              }
              return object;
            }

            function parseJWT (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                return JSON.parse(window.atob(base64));
            }
        },
        user: function(state , payload){
            state.user = payload;
        },
        language: function(state , payload){
            state.language = payload;
        },
        library: function(state , payload){
            state.library = payload;
        },
        entryURL: function(state , payload){
            state.entryURL = payload;
        },
        programs: function(state , payload){
            state.programs = payload;
        },
        bookingNotification: function(state, payload) {
            state.bookingNotification = payload;
        },
        campaign_id: function(state, payload){
            state.campaign_id = payload;
        }
    },
    modules: {
    }
});
