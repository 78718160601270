<template>
    <div>
        <div class="dashboard-title-section">
            <p class="dashboard-title">
                <str index="userDashboard > title" />
            </p>
        </div>

        <template v-for="[programId, programValue] of Object.entries(programsData)">
            <div :key="programId" class="data-table-wrapper">
                <h5 :key="`title_${programId}`" class="data-table-program-title">
                    Program: {{ programValue.name }}
                    <v-icon v-if="dataTableState[programId]==='collapsed'" title="Expand" @click="dataTableState[programId]='expanded';$forceUpdate();">mdi-chevron-right</v-icon>
                    <v-icon v-if="dataTableState[programId]==='expanded'" title="Collapse" @click="dataTableState[programId]='collapsed';$forceUpdate();">mdi-arrow-collapse-vertical</v-icon>                     
                </h5>
                <v-data-table v-if="dataTableState[programId]==='expanded'"
                    :key="`dataTable_${programId}`"
                    :headers="dataTableHeader"
                    :items="programValue.userEvents"
                    :items-per-page="-1"
                    class="elevation-1 mt-5"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-spacer />
                            <v-btn
                                depressed
                                color="primary"
                                dark
                                class="mb-2"
                                @click="downloadExcel(programId)"
                            >
                                Excel
                            </v-btn>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "AdminuserDashboard",
    created() {
        this.fetchUserData();
    },
    data() {
        return {
            programsData: {},
            dataTableHeader: [
                {
                    text: "Event Id",
                    align: "center",
                    sortable: true,
                    value: "event_id",
                },
                {
                    text: "User Id",
                    align: "center",
                    sortable: true,
                    value: "user_id",
                },
                {
                    text: "Firstname",
                    align: "center",
                    sortable: true,
                    value: "firstname",
                },
                {
                    text: "Lastname",
                    align: "center",
                    sortable: true,
                    value: "lastname",
                },
                {
                    text: "Email",
                    align: "center",
                    sortable: true,
                    value: "email",
                },
                {
                    text: "Specialty",
                    align: "center",
                    sortable: true,
                    value: "specialty",
                },
                {
                    text: "Province",
                    align: "center",
                    sortable: true,
                    value: "province",
                },
                {
                    text: "City",
                    align: "center",
                    sortable: true,
                    value: "city",
                },
                {
                    text: "Site Enrollment",
                    align: "center",
                    sortable: true,
                    value: "created",
                },
                {
                    text: "Attendance Type",
                    align: "center",
                    sortable: true,
                    value: "attendance_option",
                },
                {
                    text: "Dietary Restriction",
                    align: "center",
                    sortable: true,
                    value: "dietary_restriction",
                },
                {
                    text: "Event Enrollment",
                    align: "center",
                    sortable: true,
                    value: "event_enrollment",
                },                
                {
                    text: "Seven Day Reminder",
                    align: "center",
                    sortable: true,
                    value: "seven_day_reminder",
                },                
                {
                    text: "One Day Reminder",
                    align: "center",
                    sortable: true,
                    value: "one_day_reminder",
                },
                {
                    text: "One hour reminder",
                    align: "center",
                    sortable: true,
                    value: "one_hour_reminder",
                },
                {
                    text: "Campaign ID",
                    align: "center",
                    sortable: true,
                    value: "campaign_id",
                },                
            ],
            dataTableState: {}
        };
    },
    methods: {
        async fetchUserData() {
            let userEventApiData = {
                action: "dashboard",
                call: "fetchUserEvents",
            };
            try {
                let userEventResult = await this.sendAsyncRequest(userEventApiData);
                let programEventApiData = {
                    action: "dashboard",
                    call: "fetchProgramEvents",
                };
                let programEventResult = await this.sendAsyncRequest(programEventApiData);
                this.organizedResults(userEventResult, programEventResult);
            } catch (error) {
                this.customCLog(error);
            }
        },
        organizedResults(userEvents, programEvents) {
            let programsMap = {};
            let eventToProgramMap = {};
            for (let programEvent of programEvents) {
                let currentProgramId = programEvent.program_id;
                if (!programsMap[currentProgramId]) {
                    programsMap[currentProgramId] = {
                        name: programEvent.name,
                        state: programEvent.state,
                        start: programEvent.program_start,
                        userEvents: [],
                    };
                }
                let currentEventId = programEvent.event_id;
                eventToProgramMap[currentEventId] = currentProgramId;
                this.dataTableState[currentProgramId] = programEvent.state === 'default' ? "expanded" : 'collapsed';                
            }

            for (let userEvent of userEvents) {
                let currentEventId = userEvent.event_id;
                let programIdOfCurrentEvent = eventToProgramMap[currentEventId];
                if (programIdOfCurrentEvent) {
                    programsMap[programIdOfCurrentEvent]["userEvents"].push(userEvent);
                }
            }
            this.customCLog(programsMap);
            console.log(Object.entries(programsMap));
            this.programsData = programsMap;           
        },
        downloadExcel(programId) {
            let users = this.programsData[programId]['userEvents']
            if (!users) {
                users = []
            }
            let programName = this.programsData[programId]['name']
            this.startExport(users, this.dataTableHeader, programName)
        },
        startExport: function(users, tableHeader, programName) {
            function convertToCSV(args) {
                var result, ctr, keys, columnDelimiter, lineDelimiter, data;

                data = args.data || null;
                if (data == null || !data.length) {
                    return null;
                }

                columnDelimiter = args.columnDelimiter || ",";
                lineDelimiter = args.lineDelimiter || "\n";

                keys = Object.keys(data[0]);

                result = "";
                result += keys.join(columnDelimiter);
                result += lineDelimiter;

                data.forEach(function(item) {
                    ctr = 0;
                    keys.forEach(function(key) {
                        if (ctr > 0) {
                            result += columnDelimiter;
                        }
                        result += '"' + item[key] + '"';
                        ctr++;
                    });
                    result += lineDelimiter;
                });
                return result;
            }
            function keepExporting(exportUsers, programName) {
                var data, filename, link;
                var csv = convertToCSV({
                    data: exportUsers,
                });
                var BOM = "\uFEFF";
                if (csv === null) {
                    alert("No data available");
                    return;
                }
                let now = new Date();
                let date = `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}`;
                filename = `${programName}-${date}.csv`;
                if (!csv.match(/^data:text\/csv/i)) {
                    csv = `data:text/csv;charset=utf-8,${BOM}` + csv;
                }
                data = encodeURI(csv);

                link = document.createElement("a");
                link.setAttribute("href", data);
                link.setAttribute("download", filename);
                link.click();
            }
            let exportUsers = []
            var headers = tableHeader;
            for (let user of users) {
                var tempObj = {};
                for (var header in headers) {
                    tempObj[headers[header]["text"]] = user[headers[header]["value"]];
                }
                exportUsers.push(tempObj);
            }
            keepExporting(exportUsers, programName);
        }
    },
};
</script>

<style scoped>
.dashboard-title-section {
    margin-top: 15px;
    text-align: center;
}

.dashboard-title {
    font-size: 22px;
    font-weight: 500;
}

.data-table-wrapper {
    width: 95%;
    margin: 0 auto;
}

.data-table-program-title {
    font-size: 18px;
}
</style>
