<template>
    <div class="AttendanceOptions">
        <h2><div v-html="labelTerm('site_registration > please register here')"></div></h2>
        <p v-html="labelTerm('site_registration > how attend')"></p>
        <div class="cont_buttons">
            <div class="button" :class="selected('virtual')" @click='selectOption("virtual")'>
                <img :src="require('@/assets/icon_virtual.png')" alt="icon of computer, signifying virtual attendance">
                <p v-html="labelTerm('site_registration > virtual')"></p>
            </div>
            <div class="button" :class="selected('in-person')" @click='selectOption("in-person")'>
                <img :src="require('@/assets/icon_in-person.png')" alt="icon of figure with checkmark, signifying in-person attendance">
                <p v-html="labelTerm('site_registration > in-person')"></p>
            </div>
        </div>
    </div>
</template>

<script>
// import  from '@/components/'
export default {
    name: 'AttendanceOptions',
    data: function(){
        return {
            attendanceOption: null,
        }
    },
    methods: {
        selectOption: function(option){
            this.attendanceOption = option;
            this.$emit('UPDATE_attendanceOption', this.attendanceOption);
        },
        selected: function(option){
            if( option == this.attendanceOption){
                return 'selected';
            }
            else{
                return '';
            }
        }
    }
}
</script>

<style lang='scss' scoped>

    .AttendanceOptions {
        margin-top: 16px;
        margin-left: -12px;
        margin-bottom: 24px;

        @media(max-width: 959px){
            margin-top: 16px;
        }

        > h2 {
            font-size: 18px;
            margin-bottom: 16px;

            @media(max-width: 575px){
                font-size: 16px;
                margin-bottom: 8px;
            }
        }

        > p {
            font-size: 18px;

            @media(max-width: 575px){
                font-size: 16px;
            }
        }

        .cont_buttons {
            display: flex;
        }

        .button {
            display: flex;
            align-items: center;
            width: 208px;
            height: 56px;
            padding: 24px;
            color: white;
            background-color: rgb(8, 60, 122);
            border-radius: 3px;
            cursor: pointer;

            @media(max-width: 575px){
                padding: 16px;
            }

            &:not(:nth-of-type(1)){
                margin-left: 8px;
            }

            img {
                width: 32px;
                height: 26px;

                @media(max-width: 575px){
                    width: 24px;
                    height: 20px;
                }
            }

            p {
                margin-bottom: initial;
                margin-left: 16px;
                font-weight: bold;

                @media(max-width: 575px){
                    font-size: 12px;
                }
            }

            // STATE
            &.selected {
                background-color: rgb(31, 107, 184);
                box-shadow: 4px 4px 15px 0px rgb(0 0 0 / 25%);
            }
        }
    }
</style>
