<template>
<div>
    <h3 align='center'> Scheduled Reminders :: Functionality TBD</h3>
    <v-container>
        <v-row class="mt-4">
            <v-col cols="2"><h3 cols="1">Mock In-Person ->></h3></v-col>
            <div class="col-2">
                <v-btn disabled>
                    Reminder 1-hour
                </v-btn>
            </div>
            <div class="col-2">
                <v-btn @click="reminder_displayed='In-Person::1-day'; render_template(1, 'day', 'in-person')">
                    Reminder 1-day
                </v-btn>
            </div>
            <div class="col-2">
                <v-btn @click="reminder_displayed='In-Person::7-day'; render_template(7, 'day', 'in-person')">
                    Reminder 7-day
                </v-btn>
            </div>            
        </v-row>
        
        <v-row class="mt-4">
            <v-col cols="2"><h3>Mock Virtual ->></h3></v-col>
            <div class="col-2">
                <v-btn @click="reminder_displayed='Virtual::1-hour'; render_template(1, 'hour', 'virtual')">
                    Reminder 1-hour
                </v-btn>
            </div>
            <div class="col-2">
                <v-btn @click="reminder_displayed='Virtual::1-day'; render_template(1, 'day', 'virtual')">
                    Reminder 1-day
                </v-btn>
            </div>
            <div class="col-2">
                <v-btn @click="reminder_displayed='Virtual::7-day'; render_template(7, 'day', 'virtual')">
                    Reminder 7-day
                </v-btn>                
            </div>                            
        </v-row>
        <v-row class="mt-4" v-if="reminder_html">
            <h3 class="text-h4 text-decoration-underline">Displaying - {{reminder_displayed}}</h3>
            <v-container v-html="reminder_html"></v-container>
        </v-row>
    </v-container>
</div>
</template>

<script>

export default {
    props: {
        language: {
            type: String,
            required: false,
            default: 'en'
        }
    },
    created: function(){ },
    data: function(){
        return {
            reminder_html: null,
            reminder_displayed: null
        }
    },
    methods: {
        render_template: function(interval_span, interval_unit, attendance_option){
            return new Promise((resolve, reject)=>{
                let action = 'reminders';
                let call = 'execute_reminder_tester';
                this.reminder_html = null
                let self = this;
                if(this.sendRequest){
                    this.sendRequest({
                        action: action,
                        call: call,
                        dryRun: false,
                        interval_span: interval_span,
                        interval_unit: interval_unit,
                        attendance_option: attendance_option
                    }).then(function(response){
                        let results = response.data[action][call];
                        self.reminder_html = results;
                        resolve(results);
                    },function(){
                        reject();
                    })
                }else{
                    setTimeout(function(){
                        resolve()
                    },1000)
                }   
            })            
        },
        
        sendValidationReminderTester: function() {
            return new Promise((resolve, reject)=>{
                let action = 'reminders';
                let call = 'execute_validation_reminder_tester';

                if(this.sendRequest){
                    this.sendRequest({
                        action: action,
                        call: call,
                        days: 2,
                        dryRun: false 
                    }).then(function(response){
                        let results = response.data[action][call];
                        resolve(results);
                    },function(){
                        reject();
                    })
                }else{
                    setTimeout(function(){
                        resolve()
                    },1000)
                }   
            })
        }
    }
}
</script>