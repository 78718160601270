import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=e2189d5a&scoped=true&"
import script from "./SideBar.vue?vue&type=script&lang=js&"
export * from "./SideBar.vue?vue&type=script&lang=js&"
import style0 from "./SideBar.vue?vue&type=style&index=0&id=e2189d5a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2189d5a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VRow})
