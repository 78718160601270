<template>
  <button class='home-button' @click="goRegistration()">
      Back to Registration
  </button>
</template>

<script>
export default {
    name: "HomeButton",
    methods: {
        goRegistration() {
            this.$router.push("/");
            return;
        }
    }
}
</script>

<style scoped>
    .home-button {
        background-color: #ffffff;
        color: #2b507f;
        border: 2px solid #2b507f !important;
        padding: 15px;
        cursor: pointer;
        border: none;
        text-transform: capitalize;
        transition-duration: 0.4s;
    }

    .home-button:hover {
        background-color: #2b507f;
        color: #ffffff;
    }
</style>