const specialityList = [
    {text: {'en': 'Urologist', 'fr': 'Urologue'}, value: 'urology'},
    {text: {'en': 'Uro-Oncologist', 'fr': 'Uro-oncologue'}, value: 'uro-oncology'},
    {text: {'en': 'Medical Oncologist', 'fr': 'Oncologue médical(e)'}, value: 'medical-oncology'},
    {text: {'en': 'Radiation Oncologist', 'fr': 'Radio-oncologue'}, value: 'radiation-oncology'},
    {text: {'en': 'Internal Medicine', 'fr': 'Médecin interne'}, value: 'internal-medicine'},
    {text: {'en': 'Family Physician', 'fr': 'Médecin de famille'}, value: 'family-physician'},
    {text: {'en': 'Pharmacist', 'fr': 'Pharmacien(ne)'}, value: 'pharmacist'},
    {text: {'en': 'Nurse', 'fr': 'Infirmier(ère)'}, value: 'nurse'},
    {text: {'en': 'Other', 'fr': 'Autre'}, value: 'other'}
]

export default specialityList;
