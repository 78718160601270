<template>
    <v-row>
        <v-select :items="dropdownList" @change="$emit('input', $event)" :label="labelTerm(labelIndex)" :rules="formRules()[rule]">
            <template v-slot:selection="{item}">
                <!-- HTML that describe how select should render selected items -->
                <span v-html="item.text"></span>
            </template>
            <template v-slot:message="{ message }">
                <str :index="message" />
            </template>
        </v-select>
    </v-row>
</template>

<script>
    import provinceList from "@/js/provinceList.js"
    import specialityList from "@/js/specialityList.js"
    export default {
        name: "DropdownInput",
        props: {
            category: {
                type: String,
                required: true
            },
            value: {
                type: String,
                required: true
            },
            labelIndex: {
                type: String,
                required: true
            },
            rule: {
                type: String,
                required: false
            }
        },
        computed: {
            dropdownList() {

                let province_en = [];
                let province_fr = [];

                for(let key in provinceList){
                    let obj = provinceList[key];

                    province_en.push({
                        'text': obj.text.en,
                        'value': obj.value
                    })

                    province_fr.push({
                        'text': obj.text.fr,
                        'value': obj.value
                    })
                }

                let speciality_en = [];
                let speciality_fr = [];

                for(let key in specialityList){
                    let obj = specialityList[key];

                    speciality_en.push({
                        'text': obj.text.en,
                        'value': obj.value
                    });

                    speciality_fr.push({
                        'text': obj.text.fr,
                        'value': obj.value
                    });
                }

                const categoryMap = {
                    'province': null,
                    'speciality': null
                }

                let language = this.$store.getters.language;
                if( language == 'en'){
                    categoryMap.province = province_en;
                    categoryMap.speciality = speciality_en;
                }
                else
                if( language == 'fr'){
                    categoryMap.province = province_fr;
                    categoryMap.speciality = speciality_fr;
                }

                return categoryMap[this.category] || [];
            }
        }
    }
</script>

<style lang="css" scoped>
/deep/ label::before {
  content: "*  ";
  color: red;
}

/deep/ .theme--light.v-label {
    color: #9e9e9e;
}
</style>
