<template>
  <v-row
    :justify="$vuetify.breakpoint.lgAndUp ? 'end' : 'center'"
    :class="['mt-0', { ['image-masked']: program.state === 'closed' || program.state === 'default-closed' }, $vuetify.breakpoint.xs===false ? 'mx-0' : 'mx-0']"
  >
    <div class="viewFullInvite" @click='event_viewFullInvite()'>
        <p v-if="this.viewFullInvite == false" v-html="labelTerm('resources > view full invite')"></p>
        <div v-else class="touchspace_close"><span>X</span></div>
        <img v-if="this.viewFullInvite == false" :src="require('@/assets/newWindow.png')" alt="">
    </div>
    <img class="fullInvite" v-if="this.viewFullInvite == true && this.$store.getters.language == 'en'" :src="require('@/assets/event/event_mobile_6.png')" alt="">
    <img class="fullInvite" v-else-if="this.viewFullInvite == true && this.$store.getters.language == 'fr'" :src="require('@/assets/event/event_mobile_6_fr.png')" alt="">

    <img v-if="this.viewFullInvite == false" :src="getEventImgURL" alt="event banner" class="side-bar-image"/>
  </v-row>
</template>

<script>
export default {
    name: "SideBar",
    props: {
        program: {
          type: Object,
        },
    },
    data: function(){
        return {
            viewFullInvite: false
        }
    },
    methods: {
        getEventDesktopImgUrl() {
            try {
                let language = this.$store.getters.language;
                if( language == 'fr'){
                    return require.context("@/assets/event/", false, /\.png$/)(`./event_${this.program.id}_fr.png`);
                }
                else{
                    return require.context("@/assets/event/", false, /\.png$/)(`./event_${this.program.id}.png`);
                }
            } catch (ex) {
                return require("@/assets/logo_cdn_night.png");
            }
        },
        getEventMobileImgUrl() {
            let language = this.$store.getters.language;
            try {
                if (this.$vuetify.breakpoint.smAndUp) {
                    if( language == 'fr'){
                        return require.context("@/assets/event/", false, /\.png$/)(`./event_mobile_xs_${this.program.id}_fr.png`);
                    }
                    else{
                        return require.context("@/assets/event/", false, /\.png$/)(`./event_mobile_xs_${this.program.id}.png`);
                    }
                }
                else if(this.$vuetify.breakpoint.xs){
                    if( language == 'fr'){
                        return require.context("@/assets/event/", false, /\.png$/)(`./event_mobile_xs_${this.program.id}_fr.png`);
                    }
                    else{
                        return require.context("@/assets/event/", false, /\.png$/)(`./event_mobile_xs_${this.program.id}.png`);
                    }
                }
                else {
                    return require.context("@/assets/event/", false, /\.png$/)(`./event_mobile_xs_${this.program.id}.png`);
                }
            } catch (ex) {
                return require("@/assets/logo_cdn_night.png");
            }
        },
        event_viewFullInvite: function(){
            if( this.viewFullInvite === true){
                this.viewFullInvite = false;
            }
            else{
                this.viewFullInvite = true;
            }
        }
    },
    computed: {
        getEventImgURL() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                return this.getEventDesktopImgUrl();
            } else {
                return this.getEventMobileImgUrl();
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.image-masked {
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4));
}

.side-bar-image {
  height: auto;
  max-width: 100%;
  max-height: 1100px;
}

.viewFullInvite {
    display: none;
    position: absolute;
    // top: 16px;
    // right: 24px;
    top: -24px;
    right: 8px;
    font-weight: 600;
    cursor: pointer;

    @media(max-width: 960px){
        display: flex;
    }
    @media(max-width: 575px){
        right: 8px;
        font-size: 14px;
    }

    > p {
        color: white;
    }

    > img {
        width: 24px;
        height: 24px;
        margin-left: 8px;

        @media(max-width: 575px){
            width: 16px;
            height: 16px;
            margin-left: 4px;
            margin-top: 2px;
        }
    }

    .touchspace_close {
        margin-left: 40px;
        position: relative;
        top: 8px;
        left: -16px;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        border: 2px solid white;

        @media(max-width: 575px){
            top: 0;
            left: 0;
            width: 32px;
            height: 32px;
        }

        > span {
            color: white;
            font-size: 24px;
            font-family: arial;
            position: relative;
            left: 10px;

            @media(max-width: 575px){
                font-size: 20px;
                top: -1px;
                left: 7px;
            }
        }
    }
}

.fullInvite {
    width: 100%;
}
</style>
