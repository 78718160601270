<template>
    <div class="languageCont">
        <v-select
            class="language"
            :dark="false"
            dense
            solo
            v-model='language'
            :items='languages'
            :label="'Language'"/>
    </div>
</template>

<script>
export default {
    name: "Language",
    data: function(){
        return {
            languages: [
                {text: 'English', value: 'en'},
                {text: 'French', value: 'fr'}
            ],
            language: null
        }
    },
    created: function(){
        this.setLanguageFromNavigator();
    },
    methods: {
        setLanguageFromNavigator: function(){
            let navLang = navigator.language;
            navLang = navLang.split('-')[0];
            if( navLang != 'en' || navLang != 'fr'){
                navLang = 'en';
            }
            this.language = navLang;
        }
    },
    watch: {
        language: function(value){
            if( value == 'en' || value == 'fr'){
                this.$store.dispatch('language', value);
            }
        }
    }
}
</script>

<style lang='scss'>

    .languageCont {

        position: absolute;
        width: 100%;
        height: 72px;
        top: 2px;

        @media(max-width: 959px){
            position: initial;
            width: initial;
            top: initial;
            background-color: rgb(10, 60, 122);
        }
    }

    .language {

        --widthOfSelector: 120px;

        width: var(--widthOfSelector);
        position: relative;
        left: calc(100% - var(--widthOfSelector) - 8px);
        top: 2px;

        .v-input__control {
            min-height: 24px !important;
        }

        .v-text-field__details {
            height: 0 !important;
            margin-bottom: 0 !important;
            padding: 0 !important;
        }

        .v-label,
        .v-select__selections {
            font-size: 14px !important;

            input {
                font-size: 10px !important;
            }
        }
    }
</style>
