const provinceList = [
    {text: {'en': 'Alberta', 'fr': 'Alberta'}, value: 'AB'},
    {text: {'en': 'British Columbia', 'fr': 'Colombie-Britannique'}, value: 'BC'},
    {text: {'en': 'Manitoba', 'fr': 'Manitoba'}, value: 'MB'},
    {text: {'en': 'New Brunswick', 'fr': 'Nouveau-Brunswick'}, value: 'NB'},
    {text: {'en': 'Newfoundland and Labrador', 'fr': 'Terre-Neuve-et-Labrador'}, value: 'NL'},
    {text: {'en': 'Northwest Territories', 'fr': 'Territoires du Nord-Ouest'}, value: 'NT'},
    {text: {'en': 'Nova Scotia', 'fr': 'Nouvelle-Écosse'}, value: 'NS'},
    {text: {'en': 'Nunavut', 'fr': 'Nunavut'}, value: 'NU'},
    {text: {'en': 'Ontario', 'fr': 'Ontario'}, value: 'ON'},
    {text: {'en': 'Prince Edward Island', 'fr': 'Île-du-Prince-Édouard'}, value: 'PE'},
    {text: {'en': 'Quebec', 'fr': 'Québec'}, value: 'QC'},
    {text: {'en': 'Saskatchewan', 'fr': 'Saskatchewan'}, value: 'SK'},
    {text: {'en': 'Yukon', 'fr': 'Yukon'}, value: 'YT'}
]

export default provinceList;
