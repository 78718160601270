<template>
    <div>
        <v-app-bar color="primary" dark>
            <v-btn text @click="$router.push('/adminasco/users')">
                <str index="userDashboard > title" />
            </v-btn>
            <v-btn text @click="$router.push('/adminasco/reminders')">
                <str index="reminders_dashboard > title" />
            </v-btn>             
            <v-spacer></v-spacer>

            <v-btn text @click="logout()">
                <str index="button > logout" />
            </v-btn>
        </v-app-bar>
        
        <template>
            <AdminUserDashboard v-if="$route.name==='Admin' || $route.name==='users_dashboard'"/>
        </template>
        <Reminders v-if="$route.name==='reminders_dashboard'"/>
    </div>
</template>

<script>
import AdminUserDashboard from "@/components/Admin/AdminUserDashboard";
import Reminders from "@/components/Admin/Reminders/Reminders.vue";
export default {
    name: "Admin",
    created: function(){
        console.log(this.$route);
    },
    components: {
        AdminUserDashboard,
        Reminders        
    },
    methods: {
        async logout() {
            let logoutApiData = {
                action: "user",
                call: "logout",
            };
            try {
                await this.sendAsyncRequest(logoutApiData);
                this.$store.dispatch("clear_jwt");
                this.$router.push("/loguasco");
            } catch (error) {
                this.customCLog(error);
            }
        },        
    }
};
</script>

<style></style>
