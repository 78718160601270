<template>
    <v-container v-if="userList.length">

        <AttendanceOptions @UPDATE_attendanceOption='(given)=>this.form.attendanceOption = given' />

        <div v-if='form.attendanceOption !== null'>           
            <template v-if="form.attendanceOption=='in-person'">
                <v-row :class="showLocationDetails ? 'mb-6' : 'mb-0'">
                    <v-card width="100%" v-if="showLocationDetails" outlined shaped style="background: linear-gradient(to bottom, rgba(1, 60, 122, 1), rgba(31, 107, 184, 1));">
                            <v-icon dark small style="position: absolute; right:0" @click="showLocationDetails=false">mdi-close</v-icon>
                            <v-card-title class="white--text" v-html="registrationTargetProgram.event_geo_details.title[$store.getters.language]"></v-card-title>
                            <v-card-subtitle class="white--text" v-html="registrationTargetProgram.event_geo_details.address[$store.getters.language]"></v-card-subtitle>
                    </v-card>
                    <div v-else-if="showLocationDetails===false"> 
                        <v-card-title>
                            {{registrationTargetProgram.event_geo_details.title[$store.getters.language]}}&nbsp;
                            <v-icon small @click="showLocationDetails=true" color="#000000de">mdi-magnify-expand</v-icon>
                        </v-card-title>
                    </div>
                </v-row>
            </template>
            
            <v-form ref='registrationForm' v-model="registrationFormValid"  v-on:submit.prevent='registerUser'>
                <TextInput @input="form.firstname=$event" value="firstname" labelIndex="form > firstname" :rule="'validFirstName'"/>
                <TextInput @input="form.lastname=$event" value="lastname" labelIndex="form > lastname" :rule="'validLastName'"/>
                <TextInput @input="form.email=$event" value="email" labelIndex="form > email" :rule="'validEmail'"/>
                <TextInput @input="form.confirm_email=$event" value="confirmEmail" labelIndex="form > confirm_email" :rule="'validConfirmEmail'" :email="form.email"/>
                <TextInput @input="form.city=$event" value="city" labelIndex="form > city" :rule="'validCity'"/>
                <DropdownInput @input="form.province=$event" category="province" value="province" labelIndex="form > province" :rule="'validProvince'" />
                <DropdownInput @input="form.specialty=$event" category="speciality" value="speciality" labelIndex="form > specialty" :rule="'validSpecialty'" />
                <TextInput @input="specialty_other=$event" v-if="form.specialty=='other'" value="other-speciality" labelIndex="form > other > specialty" :rule="'validTypedSpecialty'" outlined dense/>
                <TextInput v-if='form.attendanceOption == "in-person"' @input="form.dietaryRestriction=$event" value="dietaryRestriction" labelIndex="form > dietary_restrictions"/>
            </v-form>

            <v-row style='display:flex; flex-flow:nowrap; justify-content:flex-start; align-items:center; flex: initial;'>
                <v-checkbox v-model='languageDisclaimer' style='flex: 0 1 auto;'></v-checkbox>
                <div v-html="labelTerm('site_regulation > language_disclaimer')" :style="{fontSize:'1rem', color: this.acknowledgeLanguageDisclaimer(), fontWeight:'600'}"></div>
            </v-row>

            <v-row><div v-html="labelTerm('site_regulation > usage_consent')" style="font-size:0.825rem; color:#808080;"></div></v-row>

            <v-row justify="center" class="mt-6">
                <v-btn dark @click="registerUser()" color="rgba(1, 60, 122, 1)" style='height:56px; padding:24px 48px;' class="text-h6">
                    <str index="button > submit var1" />
                </v-btn>
            </v-row>
        </div>

    </v-container>
</template>

<script>
    import AttendanceOptions from '@/components/Registration/AttendanceOptions.vue'
    import TextInput from "@/components/Inputs/TextInput.vue"
    import DropdownInput from '@/components/Inputs/DropdownInput.vue'
    export default {
        name: 'registration-form',
        props: {
            getAllUsers: {
                type: Function,
                required: true
            },
            registrationTargetProgram: {
                required: true,
                default: null
            }
        },
        components: {
            AttendanceOptions,
            TextInput,
            DropdownInput
        },
        data() {
            return {
                form: {
                    attendanceOption: null,
                    firstname: null,
                    lastname: null,
                    email: null,
                    confirm_email: null,
                    city: null,
                    province: null,
                    specialty: null,
                    dietaryRestriction: null
                },
                languageDisclaimer: null,
                registrationFormValid: false,
                userList: [],
                showLocationDetails: true,
                specialty_other: null
            }
        },
        created: function(){
            let self = this;
            this.getAllUsers().then(function(response){
                self.userList = response;
            });
        },
        methods: {
            registerUser: function() {
                if (this.$refs.registrationForm.validate() && this.languageDisclaimer===true) {
                    console.log("Form is Validated");
                    let self = this;
                    this.form.specialty = this.form.specialty==='other' ? this.form.specialty + ' - ' + this.specialty_other : this.form.specialty;
                    this.$emit("registerUser", {form: this.form, callTyp: this.userList.filter(elem => elem.email===self.form.email).length > 0 ? "book" : "create_user"});
                }
                else {
                    if( this.languageDisclaimer == null){
                        this.languageDisclaimer = false;
                    }
                    return console.log("Form Validation Failure");
                }
            },
            acknowledgeLanguageDisclaimer: function(){
                if( this.languageDisclaimer === false){
                    return 'red'
                }
                else{
                    return 'black'
                }
            }
        },
        computed: {
            attendanceOption: function(){
                return this.form.attendanceOption;
            },
            formSpecialty: function(){
                return this.form.specialty;
            }
        },
        watch: {
            attendanceOption: function(after, before){
                if (after!==before && after==='in-person'){
                    this.showLocationDetails=true;
                }
            },
            formSpecialty: function(after, before){
                if (before==='other'){
                    this.form.specialty_other=null;
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
