<template>
<div :class="$vuetify.breakpoint.mdAndUp ? 'mt-1' : 'mt-0'">
    <Language />

    <v-row :class="['justify-center', $vuetify.breakpoint.xs===true ? 'mx-0' : 'mx-0']">
        <v-col class='side_left' cols="12" sm="12" md="6" lg="6" xl="4" :class="{'px-0':$vuetify.breakpoint.xs===true}">
            <SideBar :program="registrationTargetProgram"/>
        </v-col>
        <v-col class='side_right' cols="12" sm="12" md="6" lg="5" :xl="xlColnums">
            <EventClosure v-if="registrationTargetProgram.state==='closed' || registrationTargetProgram.state==='default-closed'" :program="registrationTargetProgram"/>
            <RegistrationForm v-else :registrationTargetProgram="registrationTargetProgram" :getAllUsers="getAllUsers" @registerUser="registerUser"/>
        </v-col>
    </v-row>

    <v-dialog
        v-model="request_in_process"
        max-width="150"
        height="150"
        no-click-animation
        persistent
        overlay-opacity="0.7"
        overlay-color="white"
    >
        <v-card class="d-flex align-center justify-center" height="150" width="150" style="background: transparent" elevation="0">
            <v-card-title class="justify-center">
                <v-progress-circular
                    :size="70"
                    :width="7"
                    color="purple"
                    indeterminate
                  ></v-progress-circular>
            </v-card-title>
        </v-card>
    </v-dialog>

    <BookingNotification />

</div>

</template>

<script>
// @ is an alias to /src
import SideBar from '@/components/SideBar/SideBar.vue'
import RegistrationForm from '@/components/Registration/RegistrationForm.vue'
import EventClosure from '@/components/Event/EventClosure.vue'
import _isEmpty from "lodash/isEmpty";
import BookingNotification from '@/components/Notification/BookingNotification.vue';
import Language from '@/components/Globals/Language.vue';

/* eslint-disable no-unused-vars */
export default {
    name: 'registration-home',
    components: {
        RegistrationForm,
        SideBar,
        EventClosure,
        BookingNotification,
        Language
    },
    data: function(){
        return {
            request_in_process: false
            /*
            bookingNotification: {
                display: false,
                event_title: "Event sample",
            }
             *
             */
        }
    },
    mounted: function(){
        if (!this.registrationTargetProgram){
            this.$router.push("/*");
        }
    },
    methods: {
        getAllUsers: function(){
            return new Promise((resolve, reject)=>{
                this.sendRequest({
                    action: 'user',
                    call: 'get_all_users',
                    param: JSON.stringify({"select_field":"email"})
                }).then(function(response){
                    resolve(response.data.user.get_all_users);

                },function(response){
                    console.log("Reject", response);
                    reject([]);
                });
            });
        },
        registerUser: function(formParams) {
            let self = this;
            return new Promise((resolve, reject) =>{
                if (!_isEmpty(formParams.form.email)) {
                    let action = 'events';
                    let call = "book";
                    delete formParams.form.confirm_email;
                    self.request_in_process = true;
                    this.sendRequest({
                        action: action,
                        call : call,
                        registrant_data: formParams.form,
                        event_id: self.registrationTargetProgram.event_id,
                        event_datetime: self.registrationTargetProgram.event_datetime,
                        typ: formParams.callTyp,
                        campaign_id: self.$store.getters.campaign_id
                    }).then(function(response){
                        setTimeout(function(){
                            self.request_in_process = false;
                            let payloadBookNoti = {};
                            if (response.data[action][call].result===true){
                                payloadBookNoti = {
                                    display: true,
                                    event_title: self.registrationTargetProgram.details.title[self.$store.getters.language],
                                    event_date: self.formatDateString(self.outputDate(self.registrationTargetProgram.event_datetime).local, self.$store.getters.language),
                                    context: 'success'
                                }
                            }
                            else if (response.data[action][call].result===false){
                                payloadBookNoti = {
                                    display: true,
                                    event_title: response.data[action][call].error,
                                    event_date: self.formatDateString(self.outputDate(self.registrationTargetProgram.event_datetime).local, self.$store.getters.language),
                                    context: 'failure'
                                }
                                if (call==='book' && response.data[action][call].error.code==='23000'){
                                    payloadBookNoti.event_title = self.registrationTargetProgram.details.title[self.$store.getters.language],
                                    payloadBookNoti.context = 'success',
                                    payloadBookNoti.subcontext = "already_enrolled"
                                }
                            }
                            self.$store.dispatch('bookingNotification', payloadBookNoti);
                            resolve(response);
                        }, 3000);
                    }, function(response){
                        console.log("Reject", response);
                        self.request_in_process = false;
                        reject();
                    });
                }
                else{
                    reject();
                }
            });
        }
    },
    computed: {
        defaultProgramId: function(){
            let defaultProgramId = this.$store.getters.programs.filter(program => (program.state==='default' || program.state==='default-closed')).map(elem =>{return elem.id});
            return defaultProgramId.length>0 ? defaultProgramId[0] : null;
        },
        registrationTargetProgram: function(){
            let regTargetProgramId = this.$route.params.program_id !== undefined ? this.$route.params.program_id : this.defaultProgramId;
            return this.$store.getters.programs.filter(program => program.id==regTargetProgramId).length ?
                this.$store.getters.programs.filter(program => program.id==regTargetProgramId)[0]
                : false;
        },
        xlColnums: function(){
            return this.registrationTargetProgram.state==='closed' || this.registrationTargetProgram.state==='default-closed' ? 5 : 4;
        }
    }
}
</script>
<style lang='scss' scoped>

    .side_left {
        position: relative;

        @media(max-width: 960px){
            padding: 0;
        }
    }

    .side_right {
        padding-left: 40px;

        @media(max-width: 991px){
            padding-left: 12px;
        }
    }
</style>
